import { render, staticRenderFns } from "./UserDropdown.vue?vue&type=template&id=4dd6cc00&scoped=true&"
import script from "./UserDropdown.vue?vue&type=script&lang=js&"
export * from "./UserDropdown.vue?vue&type=script&lang=js&"
import style0 from "./UserDropdown.vue?vue&type=style&index=0&id=4dd6cc00&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4dd6cc00",
  null
  
)

export default component.exports
import {QBtnDropdown,QAvatar,QList,QItem,QItemSection,QItemLabel,QSeparator,Ripple} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QBtnDropdown,QAvatar,QList,QItem,QItemSection,QItemLabel,QSeparator})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {Ripple})
