import axios from 'axios'

let logout = {
    execute (_uid, _at) {
        axios({
            method: 'post',
            url: process.env.VUE_APP_APISERVER + 'authentication/logout',
            data: {
                _uid: _uid,
                _at: _at
              }
        })
        .then(function (response) {
            if(response.data['result'] == "success"){
                if (response.data['body']['status'] == "logged_out") {
                    this.$store.state.app.isLoggedIn = false
                    return true;
                }     
            }
            if(response.data['result'] == "failed"){
                return false;
            }
        });
    }
}
export default logout