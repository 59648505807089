//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import logout from '@/scripts/auth/logout.js';
export default {
  methods: {
    lo() {
        logout.execute(localStorage._uid, localStorage._at)
        this.$store.state.isLoggedIn = false;
        this.$store.commit('logout');
        this.$store.commit('user/logout');
        this.$router.push('/login');
      }
  },
  computed: { 
      full_name() {
        return this.$store.state.user.full_name;
      }
    }
  }
